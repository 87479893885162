.button {
    min-width: 150px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 9px 20px 8px;
    color: #FFFFFF;
    font-family: "Proxima Nova", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    border: 0;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
}

.buttonDisabled {
    opacity: 0.2;
}

.buttonGreen {
    background-color: #2BAF2B;
}

.buttonRed {
    background-color: #F50057;
}

.buttonWhite {
    background-color: transparent;
    box-shadow: none;
    color: #2BAF2B;
    border: 1px solid #2BAF2B;
    font-weight: 600;
    letter-spacing: -1.13px;
    min-width: auto;
    padding: 6px 20px;
}

.buttonTransparent {
    background-color: transparent;
    color: #2BAF2B;
    box-shadow: none;
    font-weight: 600;
    text-decoration: underline;
    letter-spacing: -1.13px;
}

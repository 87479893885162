.wrapper {
    position: relative;

    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 8px 10px;
    border: 0;
    width: 100%;
    box-sizing: border-box;

    cursor: pointer;
}

.withBorder {
    border: 1px solid rgba(0, 104, 62, 0.5);
}

.title {
    margin-bottom: 5px;
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 14px;
    letter-spacing: -0.88px;
    line-height: 21px;
}

.placeholder {
    opacity: 0.5;
}

.icon {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    cursor: pointer;

    position: absolute;
    top: 13px;

}

.iconCalendar {
    right: 10px;
    background-image: url(../../images/calendar.svg);
}

.iconClear {
    right: 35px;
    background-image: url(../../images/close-primary.svg);
}

.backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.calendar {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    z-index: 1;
    cursor: auto;
}

.calendarLeft {
    left: 0;
}

.calendarRight {
    right: 0;
}

.form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.title {
    margin-bottom: 20px;
}

.filler {
    flex: 1 1 auto;
}

.errorWrapper {
    align-self: center;
    color: #F50057;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 18px;
    text-align: center;
}

.buttonWrapper {
    margin-top: 20px;
    align-self: center;
}

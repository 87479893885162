.wrapper {
    background-color: #FFFFFF;
    box-shadow: 2px 0 16px 0 rgba(0,104,62,0.1);

    position: relative;
    width: 100px;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    padding-top: 150px;
    padding-bottom: 50px;
    transition: width 400ms;
}

.uncollapsed {
    width: 250px;
}

.logoWrapper {
    position: absolute;
    top: 70px;
    left: 15px;
    height: 25px;
    width: 70px;
    background-image: url(../../images/Logo_POM_Pos.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transition: left 400ms, width 400ms, height 400ms;
}

.uncollapsed .logoWrapper {
    left: 35px;
    width: 118px;
    height: 41px;
}

.menuItem {
    flex: 0 0 auto;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 35px;
    margin: 10px 0;
    position: relative;
    text-decoration: none;
}

.activeHandler {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    border-radius: 2px;
    background-color: #2BAF2B;
}

.menuItem:global(.active) .activeHandler {
    display: block;
}

.icon {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
}

.menuItemTitle {
    flex: 0 0 auto;
    margin-left: 20px;
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -1.13px;
    line-height: 20px;
    opacity: 0;
    transition: opacity 200ms;
}

.uncollapsed .menuItemTitle {
    opacity: 1;
}

.spacer {
    flex: 1 1 0;
}

.iconHome {
    background-image: url(../../images/nav-home.svg);
}

.menuItem:global(.active) .iconHome {
    background-image: url(../../images/nav-home-active.svg);
}

.iconUsers {
    background-image: url(../../images/nav-users.svg);
}

.menuItem:global(.active) .iconUsers {
    background-image: url(../../images/nav-users-active.svg);
}

.iconDocuments {
    background-image: url(../../images/nav-documents.svg);
}

.menuItem:global(.active) .iconDocuments {
    background-image: url(../../images/nav-documents-active.svg);
}

.iconTransactions {
    background-image: url(../../images/nav-transactions.svg);
}

.menuItem:global(.active) .iconTransactions {
    background-image: url(../../images/nav-transactions-active.svg);
}

.iconExports {
    background-image: url(../../images/nav-exports.svg);
}

.menuItem:global(.active) .iconExports {
    background-image: url(../../images/nav-exports-active.svg);
}

.iconSms {
    background-image: url(../../images/nav-sms.svg);
}

.menuItem:global(.active) .iconSms {
    background-image: url(../../images/nav-sms-active.svg);
}

.iconPaylinks {
    background-image: url(../../images/nav-billings.svg);
}

.menuItem:global(.active) .iconPaylinks {
    background-image: url(../../images/nav-billings-active.svg);
}

.iconUncollapse {
    background-image: url(../../images/nav-uncollapse.svg);
}

.iconCollapse {
    background-image: url(../../images/nav-collapse.svg);
}

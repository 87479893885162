.wrapper {
    min-height: 100%;

    display: flex;
    align-items: center;

    margin: 0 auto;
    max-width: 1140px;
    padding: 0 20px;
}

.inner {
    flex-grow: 1;
}

.titleWrapper {
    margin-top: 40px;
    margin-bottom: 40px;
}

.tileBarWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 80px;
}

.tileBarCol {
    padding: 0 10px;
    margin-bottom: 20px;
    flex: 0 0 25%;
    box-sizing: border-box;
}

.tile {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 16px 0 rgba(0,104,62,0.15);
    padding: 30px;
    cursor: pointer;
}

.companyLogo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin: 10px auto 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url(../../images/company-logo-fallback.png);
}

.companyName {
    text-align: center;
}

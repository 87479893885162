.wrapper {
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    font-weight: bold;
    line-height: 20px;
}

.icon {
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    margin-right: 5px;
}


.wrapper-DONE {
    background-color: #dff3df;
    color: #2BAF2B;
}

.wrapper-DONE .icon {
    background-image: url("../../images/status-paid.svg");
}


.wrapper-NEW,
.wrapper-VALIDATING,
.wrapper-VALIDATED,
.wrapper-RUNNING {
    background-color: #d9e8e2;
    color: #00683E;
}

.wrapper-NEW .icon,
.wrapper-VALIDATING .icon,
.wrapper-VALIDATED .icon,
.wrapper-RUNNING .icon {
    background-image: url("../../images/status-pending.svg");
}


.wrapper-CANCELLED,
.wrapper-ERROR {
    background-color: #fed9e6;
    color: #F50057;
}

.wrapper-CANCELLED .icon,
.wrapper-ERROR .icon {
    background-image: url("../../images/status-unpaid.svg");
}

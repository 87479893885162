.detailsRightWrapper {
    text-align: right;
    font-size: 14px;
    letter-spacing: -0.88px;
    line-height: 20px;
}

.primaryID {
    margin-top: 30px;
    font-style: italic;
    font-size: 14px;
    letter-spacing: -0.88px;
    line-height: 20px;
    overflow-wrap: break-word;
    max-width: 850px;
}

.primaryID span {
    white-space: nowrap;
}

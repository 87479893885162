.sticky {
    top: -50px;
    position: sticky;
    overflow-x: hidden;
    background-color: #F7FAF9;
}

.wrapper {
    display: inline-flex;
    padding: 12px 20px;
    margin-bottom: 5px;
}

.header {
    flex: 0 0 auto;
    color: #2BAF2B;
    font-family: "Proxima Nova";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.75px;
    line-height: 16px;
    text-transform: uppercase;
    margin-left: 25px;

    display: flex;
    align-items: center;
}

.header:first-child {
    margin-left: 0;
}

.icon {
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
    margin-left: 5px;
    cursor: pointer;
}

.sortableIcon {
    background-image: url(../../images/sort-none.svg);
}

.sortableUpIcon {
    background-image: url(../../images/sort-up.svg);
}

.sortableDownIcon {
    background-image: url(../../images/sort-down.svg);
}

.filterableIcon {
    background-image: url(../../images/filter.svg);
    opacity: 0.5;
}

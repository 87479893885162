.wrapper {
    height: 5px !important;
    left: 250px !important;
    right: 0 !important;
    width: auto !important;
}

:global(body.menu-left-closed) .wrapper {
    left: 100px !important;
}

.wrapper > div {
    border-radius: 0 5px 5px 0;
    background-color: #2BAF2B !important;
}

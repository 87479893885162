.progressBarContainer {
    height: 5px;
    border-radius: 2.5px;
    background-color: #e6f0ec;
    position: relative;
    width: 200px;
}

.progressBar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 2.5px;
    background-color: #2BAF2B;
    transition: width 400ms;
}
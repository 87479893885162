.scrollWrapper {
    overflow-x: auto;
}

/* width */
.scrollWrapper::-webkit-scrollbar {
    width: 15px;
}

/* Track */
.scrollWrapper::-webkit-scrollbar-track {
    background: #c6ddd4;
    border-radius: 4px;
}

/* Handle */
.scrollWrapper::-webkit-scrollbar-thumb {
    background: #3e8d6d;
    border-radius: 4px;
}

.scrollWrapper::-webkit-scrollbar-button:horizontal:start:decrement,
.scrollWrapper::-webkit-scrollbar-button:horizontal:end:increment {
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 4px;
    background-color: #00683E;
    background-size: 9px 9px;
    background-position: center;
    background-repeat: no-repeat;
}

.scrollWrapper::-webkit-scrollbar-button:horizontal:start:decrement {
    background-image: url(../../images/arrow-left-white.svg);
}

.scrollWrapper::-webkit-scrollbar-button:horizontal:end:increment {
    background-image: url(../../images/arrow-right-white.svg);
}


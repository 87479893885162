.wrapper {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    padding: 20px 0 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.year {
    height: 37px;
    width: 97px;
    border-radius: 4px;
    background-color: rgba(0,104,62,0.05);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 5px;
}

.today {
    border: 1px solid #00683E;
}

.selected {
    background-color: #00683E;
    color: #FFFFFF;
}

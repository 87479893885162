.pane {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 30px 30px 10px 30px;
    max-width: 790px;

    display: flex;
}

.col {
    flex: 0 0 50%;
    padding: 0 10px;
    box-sizing: border-box;
}

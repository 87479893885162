.spacer {
    margin-bottom: 20px;
}

.notificationWrapper {
    display: flex;
    align-items: center;
}

.notificationCheckboxWrapper {
    margin-right: 12px;
    cursor: pointer;
}

.notificationTextWrapper {
    text-align: left;
}

.qrCodeWrapper {
    text-align: center;
}

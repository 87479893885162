.h1 {
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -2.5px;
    line-height: 60px;
    margin: 0;
}

.h2 {
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1.25px;
    line-height: 30px;
    margin: 0;
}

.h3 {
    color: #2BAF2B;
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1.25px;
    line-height: 30px;
    margin: 0;
}

.button {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    background-color: #00683E;
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.25);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}


.columns {
    background-image: url(../../images/columns.svg);
}

.search {
    background-image: url(../../images/search.svg);
}

.download {
    background-image: url(../../images/export.svg);
}

.add {
    background-image: url(../../images/add.svg);
}
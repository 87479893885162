.loaderWrapper {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    background-image: url(../../images/loading-spinner.svg);
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    animation: spin 2s linear infinite;
}

.referenceWrapper {
    display: flex;
    margin-bottom: 20px;
}

.referenceTextfieldWrapper {
    flex: 1 1 0;
}

.referenceButtonWrapper {
    margin-left: 10px;
    flex: 0 0 auto;
    width: 80px;
}

.notificationWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.notificationCheckboxWrapper {
    margin-right: 12px;
    cursor: pointer;
}

.notificationTextWrapper {
    text-align: left;
}

.formWrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 1200px;
}

.formWrapper > * {
    margin-bottom: 20px;
    margin-left: 20px;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.filler {
    flex: 1 1 auto;
}

.iconsWrapper {
    display: flex;
}

.iconWrapper {
    margin-left: 10px;
}

.tableWrapper {
    margin-top: 20px;
}

.footerWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.wrapper {
    display: flex;
    height: 100%;
}

.menuWrapper {
    flex: 0 0 auto;
    display: flex;
}

.content {
    flex: 1 1 0;
    min-width: 0;
    padding: 50px 50px 0;
    overflow-y: auto;
    position: relative;
}

.content:after {
    content:'';
    display:block;
    padding-bottom:50px;
}

.wrapper {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.titleWrapper {
    margin-bottom: 20px;
}

.description {
    text-align: center;
}

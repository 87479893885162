.titleWrapper {
    margin-bottom: 30px;
}

.primaryFieldsWrapper {
    margin-bottom: 30px;
}

.fieldsWrapper {
    margin-bottom: 75px;
}

.smsMessageWrapper {
    white-space: pre-wrap;
}

.fileContentsWrapper {
    display: flex;
    margin-bottom: 75px;
}

.actionBarWrapper {
    margin-bottom: 55px;
}

.statusRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.progressBarContainer {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.list {
    margin-top: 0;
}
.wrapper {
    margin-bottom: 20px;
}

.label {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.88px;
    line-height: 21px;
}

.value {
    line-height: 20px;
    word-break: break-all;
}

.item {
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px 10px 8px 0;

    cursor: pointer;

    display: flex;
}

.itemChecked {
    font-weight: 600;
}

.withBorder {
    border: 1px solid rgba(0, 104, 62, 0.5);
}

.icon {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 0 40px;
    align-self: center;
}

.iconEmpty {
    background-image: url(../../images/radiobutton-off.svg);
}

.iconChecked {
    background-image: url(../../images/radiobutton-on.svg);
}

.withBorder .value {
    flex: 1 1 auto;
}

.wrapper {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    padding: 20px 22px 25px 22px;
    width: 340px;
    box-sizing: border-box;
}

.title {
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -1.13px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 20px;
}

.bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 20px;
}

.barItem {
    width: 80px;
    color: #2BAF2B;
    font-weight: 600;
    text-align: center;
}

.icon  {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    cursor: pointer;
    opacity: 0.8;
}

.iconLeft {
    background-image: url(../../images/arrow-left-dark.svg);
    margin-right: 5px;
}

.iconRight {
    background-image: url(../../images/arrow-right-dark.svg);
    margin-left: 5px;
}

.quarters {
    display: flex;
    flex-wrap: wrap;
}

.quarter {
    height: 60px;
    width: 142px;
    border-radius: 4px;
    background-color: rgba(0,104,62,0.05);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 2.5px 5px 2.5px;
}

.today {
    border: 1px solid #00683E;
}

.selected {
    background-color: #00683E;
    color: #FFFFFF;
}

.wrapper {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 30px;

    min-width: 790px;
}

@media only screen and (max-width: 1200px) {
    .wrapper {
        min-width: auto;
        width: 0;
        flex: 1 1 0;
    }
}

.table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table th {
    color: #2BAF2B;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.75px;
    line-height: 16px;
    text-transform: uppercase;
    padding-left: 25px;
}

.table th:first-child {
    padding-left: 0;
}

.table td {
    padding-left: 25px;
}

.table td:first-child {
    padding-left: 0;
}

.footnote {
    font-style: italic;
    font-size: 14px;
    letter-spacing: -0.88px;
    line-height: 20px;
}



/* width */
.table::-webkit-scrollbar {
    width: 15px;
}

/* Track */
.table::-webkit-scrollbar-track {
    background: #c6ddd4;
    border-radius: 4px;
}

/* Handle */
.table::-webkit-scrollbar-thumb {
    background: #3e8d6d;
    border-radius: 4px;
}

.table::-webkit-scrollbar-button:horizontal:start:decrement,
.table::-webkit-scrollbar-button:horizontal:end:increment {
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 4px;
    background-color: #00683E;
    background-size: 9px 9px;
    background-position: center;
    background-repeat: no-repeat;
}

.table::-webkit-scrollbar-button:horizontal:start:decrement {
    background-image: url(../../images/arrow-left-white.svg);
}

.table::-webkit-scrollbar-button:horizontal:end:increment {
    background-image: url(../../images/arrow-right-white.svg);
}
.wrapper {
    height: 100%;
    background-image: url("../../images/background-desktop.jpg");
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 400px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #F7FAF9;
    padding: 75px 50px 50px 50px;
}

.logo {
    height: 75px;
    width: 220px;
    background-image: url(../../images/Logo_POM_Pos.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
}

.error {
    border-radius: 10px;
    background-color: #F50057;
    color: #F7FAF9;
    font-family: "Proxima Nova";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 18px;
    padding: 10px;
    margin-bottom: 20px;
}

.usernameWrapper {
    margin-bottom: 10px;
}

.passwordWrapper {
    margin-bottom: 40px;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
}

.qrCodeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
}

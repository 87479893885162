.wrapper {
    display: flex;
    align-items: center;
}

.label {
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: -0.88px;
    line-height: 21px;
}

.select {
    width: 143px;
}

.month {
    width: 150px;
}

.wrapper {
    height: 100%;
    background-image: url("../../images/background-desktop.jpg");
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    max-width: 350px;
    width: 100%;
    margin: 0 20px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #F7FAF9;
    padding: 75px 20px 40px 20px;
}

.logoWrapper {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.logo {
    background-image: url(../../images/Logo_POM_Pos.svg);
    background-size: contain;
    background-repeat: no-repeat;

    height: 0;
    padding-top: 33.82%;
}

.text {
    text-align: center;
}

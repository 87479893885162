.subtitleWrapper {
    margin-bottom: 30px;
    overflow-wrap: break-word;
}

.primaryFieldsWrapper {
    margin-bottom: 30px;
}

.primaryField {
    color: #00683E;
    font-family: "Proxima Nova", sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -1.88px;
    line-height: 40px;
    margin-bottom: 30px;
}

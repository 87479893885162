.wrapper {
    border-radius: 5px;
    background-color: #2BAF2B;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

.text {
    color: #FFFFFF;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.88px;
    line-height: 20px;
    max-width: 350px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
}

.closeIcon {
    background-image: url(../../images/remove-filter.svg);
    width: 10px;
    height: 10px;
    background-size: 10px 10px;
}

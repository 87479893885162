.wrapper {
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    font-weight: bold;
    line-height: 20px;
}

.icon {
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    margin-right: 5px;
}

.wrapper-paid,
.wrapper-COMPLETED {
    background-color: #dff3df;
    color: #2BAF2B;
}

.wrapper-paid .icon,
.wrapper-COMPLETED .icon {
    background-image: url("../../images/status-paid.svg");
}

.wrapper-pending,
.wrapper-PROCESSING {
    background-color: #d9e8e2;
    color: #00683E;
}

.wrapper-pending .icon,
.wrapper-PROCESSING .icon {
    background-image: url("../../images/status-pending.svg");
}

.wrapper-rejected,
.wrapper-FAILED {
    background-color: #fed9e6;
    color: #F50057;
}

.wrapper-rejected .icon,
.wrapper-FAILED .icon {
    background-image: url("../../images/status-unpaid.svg");
}

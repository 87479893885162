.filterWrapper {
    margin-bottom: 40px;
}

.titleWrapper {
    margin-bottom: 20px;
}

.filterRow {
    display: flex;
}

.filterCol {
    flex: 0 1 50%;
}

.filterCol:first-child {
    margin-right: 10px;
}

.filterCol:last-child {
    margin-left: 10px;
}

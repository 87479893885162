.container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    opacity: 0.5;
    background-color: #00683E;
}

.wrapper {
    position: relative;
    width: 400px;
    margin: 10vh auto;
    z-index: 1;

    background-color: #FFFFFF;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    border-radius: 10px;

    padding: 50px 50px 40px 50px;
    box-sizing: border-box;
}

.close {
    width: 10px;
    height: 10px;
    background-size: 10px 10px;
    cursor: pointer;
    background-image: url(../../images/close-dark.svg);
    position: absolute;
    top: 20px;
    right: 20px;
}

.modal {
    text-align: center;
}

.title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -1.25px;
    line-height: 30px;
}

.body {
    margin-bottom: 40px;
}

.progressBarContainer {
    margin: 0 auto 40px;
    display: flex;
    justify-content: center;
}

.errorWrapper {
    color: #F50057;
    margin-bottom: 20px;
}

.buttonBar {
    display: flex;
    justify-content: space-evenly;
}

.small {
    text-align: left;
    font-size: 14px;
}

.textareaWrapper {
    margin-bottom: 10px;
}
.iconWrapper {
    display: block;
    margin: 0 auto;
}

.icon {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    margin: 0 auto;
}

.dots {
    background-image: url(../../images/more-dots.svg);
}

.download {
    background-image: url(../../images/download.svg);
}

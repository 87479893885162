.wrapper {
    position: relative;

    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 8px 10px;
    border: 0;
    width: 100%;
    box-sizing: border-box;

    cursor: pointer;
}

.icon {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    cursor: pointer;

    position: absolute;
    top: 13px;

}

.iconCalendar {
    right: 10px;
    background-image: url(../../images/calendar.svg);
}

.backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.calendar {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    z-index: 1;
}

.calendarLeft {
    left: 0;
}

.calendarRight {
    right: 0;
}

.field {
    margin-bottom: 20px;
}

.textarea {
    margin-bottom: 10px;
}

.small {
    font-size: 14px;
}

.center {
    text-align: center;
}
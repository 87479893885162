.alert {
    width: 300px;
    border-radius: 10px;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    padding: 30px;
    color: #FFFFFF;
    font-weight: 600;
    overflow-wrap: break-word;
    pointer-events: all;
    margin-right: 50px;

    position: relative;
    margin-top: 20px;
}

.alert.info {
    background-color: #2BAF2B;
}

.alert.success {
    background-color: #00683E;
}

.alert.error {
    background-color: #F50057;
}

.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 10px;
    width: 10px;
    background-image: url(../../images/remove-filter.svg);
    background-size: 10px 10px;
    cursor: pointer;
}

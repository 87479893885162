.wrapper {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    padding: 20px 22px 25px 22px;
    width: 340px;
    box-sizing: border-box;
}

.title {
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -1.13px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 20px;
}

.bar {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 20px;
}

.barItem {
    width: 80px;
    color: #2BAF2B;
    font-weight: 600;
    text-align: center;
}

.icon  {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    cursor: pointer;
    opacity: 0.8;
}

.iconLeft {
    background-image: url(../../images/arrow-left-dark.svg);
    margin-right: 5px;
}

.iconRight {
    background-image: url(../../images/arrow-right-dark.svg);
    margin-left: 5px;
}

.spacer {
    flex-grow: 1;
}

.daysHeader {
    display: flex;
    margin-bottom: 10px;
}

.dayHeader {
    opacity: 0.5;
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 12px;
    letter-spacing: -0.75px;
    line-height: 18px;
    text-align: center;

    width: 38px;
    margin-left: 2px;
    margin-right: 2px;
}

.days {
    display: flex;
    flex-wrap: wrap;
}

.day {
    height: 37px;
    width: 38px;
    border-radius: 4px;
    background-color: rgba(0,104,62,0.05);
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    line-height: 19px;

    cursor: pointer;
}

.weekend {
    background-color: rgba(0,104,62,0.02);
}

.disabled {
    background-color: rgba(0,104,62,0.02);
    cursor: auto;
    opacity: 0.5;
}

.today {
    border: 1px solid #00683E;
    height: 35px;
    width: 36px;
}

.selected {
    background-color: #00683E;
    color: #FFFFFF;
    opacity: 1;
}


.formWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 1200px;
}

.formWrapper > * {
    margin-bottom: 20px;
    margin-left: 20px;
}

.chartWrapper {
    max-width: 1200px;
    height: 430px;
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
}

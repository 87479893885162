.item {
    border-radius: 10px;
    background-color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px 10px;

    cursor: pointer;

    display: flex;
}

.itemContent {
    margin-left: 10px;
    margin-right: 10px;
    flex: 1 1 0;
}

.itemChecked {
    font-weight: 600;
}

.icon {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 0 auto;
    align-self: center;
}

.iconSortable {
    background-image: url(../../images/change-order.svg);
    cursor: move;
}

.wrapper {
    display: flex;
    align-items: center;
}

.icon  {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    opacity: 0.8;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.iconDisabled {
    opacity: 0.25;
    pointer-events: none;
}

.iconDoubleLeft {
    background-image: url(../../images/arrow-left-dark-double.svg);
}

.iconLeft {
    background-image: url(../../images/arrow-left-dark.svg);
}

.iconDoubleRight {
    background-image: url(../../images/arrow-right-dark-double.svg);
}

.iconRight {
    background-image: url(../../images/arrow-right-dark.svg);
}

.number {
    margin-left: 5px;
    margin-right: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    min-width: 30px;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;

    opacity: 0.8;
    color: #00683E;
    font-weight: bold;
    text-decoration: none;
}

.numberActive {
    background-color: #00683E;
    border-radius: 15px;
    color: white;
    pointer-events: none;
    padding-left: 10px;
    padding-right: 10px;
}

.numberDisabled {
    pointer-events: none;
}

.wrapper {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 8px 10px;
    display: flex;
    cursor: pointer;
    position: relative;
}

.content {
    flex: 1 1 0;
    margin-right: 10px;
}

.icon {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 0 auto;
    align-self: center;
}

.iconOpen {
    background-image: url(../../images/dropdown-open.svg);
}

.iconClosed {
    background-image: url(../../images/dropdown-closed.svg);
}

.withBorder {
    border: 1px solid rgba(0, 104, 62, 0.5);
}

.list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 5px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    padding: 5px 10px;
    z-index: 1;
    max-height: 400px;
    overflow-y: scroll;
}

.listItem {
    margin-top: 5px;
    margin-bottom: 5px;
}

.listItemActive {
    color: #2BAF2B;
    font-weight: 600;
}

.textfield { /* input don't follow body defaults */
    color: #00683E;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    line-height: 1;

    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 1px 5px;
    border: 1px solid rgba(0, 104, 62, 0.5);
    box-sizing: border-box;
    outline: 0;

    width: 45px;
}
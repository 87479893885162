.barWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 870px;
    margin-left: -10px;
    margin-right: -10px;
}

.col {
    padding: 0 10px;
    flex: 0 0 33%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.tileWrapper {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 16px 0 rgba(0,104,62,0.15);
    padding: 30px;
    cursor: pointer;
    height: 100%;
    box-sizing: border-box;
}

.label {
    text-align: center;
}

.icon {
    height: 50px;
    width: 50px;
    background-size: 50px 50px;
    margin: 0 auto 20px auto;
}

.iconSecurity {
    background-image: url(../../images/actions-security.svg);
}

.iconPassword {
    background-image: url(../../images/actions-reset-password.svg);
}

.iconUserManagement {
    background-image: url(../../images/actions-user-management.svg);
}

.iconSwitchPortal {
    background-image: url(../../images/actions-switch-portal.svg);
}

.iconEdit {
    background-image: url(../../images/edit.svg);
}

.iconDownloadOriginal {
    background-image: url(../../images/download-original.svg);
}

.iconDownloadProcessed {
    background-image: url(../../images/download-processed.svg);
}

.iconProcess {
    background-image: url(../../images/process-file.svg);
}

.iconCancel {
    background-image: url(../../images/cancel-file.svg);
}

.wrapper {
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;

    border-radius: 10px;
    background-color: #FFFFFF;
    margin-bottom: 5px;
}

.itemWrapper {
    flex: 0 0 auto;
    margin-left: 25px;
}

.itemWrapper:first-child {
    margin-left: 0;
}

.item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block; /* Make sure tooltip only shows when hovering over actual content */
    max-width: 100%;
    vertical-align:top; /* Bugfix where overflow hidden adds height to parent */
    padding-right: 1px; /* Bugfix where overflow hidden cuts off text with negative letter-spacing */
}

.row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.statusWrapper {
    margin-right: 20px;
}

.amount {
    color: #00683E;
    font-family: "Proxima Nova", sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -1.88px;
    line-height: 40px;
}

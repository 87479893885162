.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    opacity: 0.5;
    background-color: #00683E;
    z-index: 1;
}

.wrapper {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 400px;
    margin-right: -420px; /* Add extra space, otherwise shadow is always visible */

    background-color: #F7FAF9;
    box-shadow: -2px 0 16px 0 rgba(0,104,62,0.1);

    padding: 30px 30px 50px 30px;
    box-sizing: border-box;
    transition: margin-right 400ms;

    display: flex;
    flex-direction: column;
    z-index: 1;
    overflow: auto;
}

.rendered {
    margin-right: 0;
}

.close {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    background-size: 10px 10px;
    cursor: pointer;
    background-image: url(../../images/close-dark.svg);
    margin-bottom: 30px;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-top: 10px;
}

.item {
    margin-right: 10px;
    margin-bottom: 10px;
}
